/**
=========================================================
* Farazbit React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Farazbit React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { authContext } from "context/auth.context";
import { login } from "api/auth";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const { setAuth, auth } = useContext(authContext)
  const [input, setInput] = useState({})
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  function toBasicAuth(username, password) {
    const encodedCredentials = btoa(`${username}:${password}`);
    return `Basic ${encodedCredentials}`;
  }


  function changeInput(key, value) {
    setInput({
      ...input,
      [key]: key === "username" ? value.toLowerCase() : value
    })
  }

  function signinHandler() {
    console.log(input);
    
    login(input.username, input.password).then(response => {
      if (response.status)
        return setAuth(toBasicAuth(input.username, input.password));
      alert("Invalid login");
      setAuth("")
    })
      .catch(e => {
        console.trace(e);
        alert("Failed to connect server")
      })

  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput name="username" value={input.username} onChange={e => changeInput("username", e.target.value)} type="text" label="Username" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput name="password" value={input.password} onChange={e => changeInput("password", e.target.value)} type="password" label="Password" fullWidth />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton onClick={signinHandler} variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>

          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
