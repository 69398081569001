import { useEffect, useState } from "react"
import {getStatistics} from "../api"


export function useStatistics() {
    const [statistics, setStatistics] = useState({
        userCount: "N/A",
        todayRegisteration: "N/A",
    })
    const [error, setError] = useState(false)
    useEffect(() => {
        getStatistics().then(statistics => {
            setStatistics(statistics)
            console.log({statistics});
            
        })
        .catch(e => {
            console.error(e);
            setError(true);
        })
    }, [])

    return {
        statistics,
        error
    }

}