import { useEffect, useState } from "react"
import {getNewUsers} from "../api"


export function useUsers() {
    const [users, setUsers] = useState([])
    const [error, setError] = useState(false)
    useEffect(() => {
        getNewUsers().then(users => {
            console.log(users);
            setUsers(users)
        })
        .catch(e => {
            console.error(e);
            setError(true);
            
        })
    }, [])

    return {
        users,
        error
    }

}