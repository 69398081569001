import { Icon } from "@mui/material";
import { getNewUsers } from "api";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useUsers } from "../../../hooks/users.hook";
import { useEffect, useState } from "react";


  
  const User = ({ username, invited_count }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Icon fontSize="small">person</Icon>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {username}
        </MDTypography>
        <MDTypography variant="caption">
          Invite Count: {invited_count}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const InviteCount = ({ registerDate }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {new Date(registerDate).toLocaleDateString("fa-IR")}
      </MDTypography>
    </MDBox>
  );


  export function useUserTableData() { 
    const {users, error} = useUsers()
    const [userInfo, setUserInfo] = useState([])

    useEffect(() => {
      const mappedUserIngfo = users.map((user) => {
        return {
            username: <User username={user.username === "null" ? "N/A":user.username } invited_count={user.invited_count} />,
            registerDate: <InviteCount registerDate={user.start_date} />,
          }
        
      })
      setUserInfo(mappedUserIngfo)
      console.log({mappedUserIngfo});
      
    }, [users])
    
    return {
      columns: error? [] : [
        { Header: "username", accessor: "username", width: "45%", align: "left" },
        { Header: "register date", accessor: "registerDate", align: "center" },
      ],
      
      rows: userInfo,
    };
    
  }