const { createContext, useState, useEffect } = require("react");

export const authContext = createContext({ setAuth: () => { }, auth: "" })


export function AuthContextProvider({ children }) {
    const [auth, setAuth] = useState("")
    useEffect(() => {
        // setAuth(localStorage.getItem("auth") || "")
    }, [])
    useEffect(() => {
        // localStorage.setItem("auth", auth)
    }, [auth])
    const authData = { auth, setAuth }
    return <authContext.Provider value={authData}>{children}</authContext.Provider>
}